"use client"
import "@/app/(tailwind)/Projects/[parsid]/datasheet/_styles/navigation.css"
import { ModeToggle } from "@/app/components/DarkMode"
import { signOut } from "next-auth/react"
import Head from "next/head"
import Link from "next/link"
import { PropsWithChildren, useEffect, useState } from "react"
import { getUserPermissions } from "../_utils/permissions/actions/getUserPermissions"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"

import {
  NavigationMenu,
  NavigationMenuInternalLink,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/app/components/ui/navigation-menu"
import { ChevronDown, Menu } from "lucide-react"
import { cn } from "../_utils"
import { check } from "../_utils/permissions/actions/check"
import { getAuthenticatedUserWithName } from "../_utils/permissions/actions/getAuthenticatedUser"
import { PARSLogo } from "../components/ui/parsLogo"
import {
  EmpowerLink,
  EmpowerNavMenuLink,
} from "./_components/empowerLinkButton"

export function Header() {
  const [visibilityState, setVisibilityState] = useState({
    admin: false,
    reviews: false,
  })

  const [userName, setUserName] = useState("")

  useEffect(() => {
    async function getUserData() {
      const [admin, reviewList, session] = await Promise.all([
        check("admin", "PARS:production", "ui"),
        getUserPermissions({ resourceType: "Review" }),
        getAuthenticatedUserWithName(),
      ])
      const reviews = Object.entries(reviewList ?? {}).length > 0
      setVisibilityState({ admin, reviews })
      setUserName(session?.user?.name ?? "")
    }
    getUserData()
  }, [])

  return (
    <>
      <Head>
        <title>PARS</title>
      </Head>
      <nav>
        <NavigationMenu className="z-20 size-full max-w-full justify-between bg-nav-background py-2 text-nav-text dark:bg-nav-background-dark dark:text-nav-text-dark">
          {" "}
          <NavigationMenuList>
            <NavigationMenuItem>
              <div className="mr-4 flex items-center">
                <PARSLogo />
              </div>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuInternalLink href="/Projects">
                Projects
              </NavigationMenuInternalLink>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuInternalLink href="/Contracts">
                Contracts
              </NavigationMenuInternalLink>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuInternalLink href="/Reports">
                Reports
              </NavigationMenuInternalLink>
            </NavigationMenuItem>
            {visibilityState.reviews && (
              <NavigationMenuItem>
                <NavigationMenuInternalLink href="/Reviews">
                  Reviews
                </NavigationMenuInternalLink>
              </NavigationMenuItem>
            )}
            <NavigationMenuItem>
              <NavigationMenuInternalLink href="/ReportBuilder">
                Report Builder
              </NavigationMenuInternalLink>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <EmpowerNavMenuLink isAdmin={visibilityState.admin} />
            </NavigationMenuItem>

            <NavigationMenuItem>
              <NavigationMenuLink
                href="https://wiki.pars.doe.gov/"
                className={navigationMenuTriggerStyle()}
              >
                Help
              </NavigationMenuLink>
            </NavigationMenuItem>
          </NavigationMenuList>
          <NavigationMenuList className="self-end">
            <NavigationMenuItem>{userName}</NavigationMenuItem>
            {visibilityState.admin && <AdminMenu />}
            <NavigationMenuItem
              className={`${navigationMenuTriggerStyle()} cursor-pointer`}
              onClick={() => signOut()}
            >
              Sign Out
            </NavigationMenuItem>
            <NavigationMenuItem>
              <ModeToggle />
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </nav>
    </>
  )
}

function NavigationMenuView(props: Readonly<PropsWithChildren>) {
  return (
    <li className="list-none">
      <div className="hidden items-center lg:inline-flex">{props.children}</div>
      <div className="mr-2 lg:hidden">
        <DropdownMenu>
          <DropdownMenuTrigger
            onClick={(e) => e.stopPropagation()}
            className={cn(navigationMenuTriggerStyle())}
          >
            <Menu />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="dropdown-nav mr-2 w-80 list-none lg:hidden">
            {props.children}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </li>
  )
}

function AdminMenu() {
  return (
    <NavigationMenuItem>
      <DropdownMenu>
        <DropdownMenuTrigger
          onClick={(e) => e.stopPropagation()}
          className={cn(navigationMenuTriggerStyle())}
        >
          Admin <ChevronDown />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <Link href="/admin/SubProjectAudit">
            <DropdownMenuItem className="cursor-pointer">
              SubProject Audit
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/ManageMetadata">
            <DropdownMenuItem className="cursor-pointer">
              Manage Metadata
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/users-and-contacts">
            <DropdownMenuItem className="cursor-pointer">
              Users and Contacts
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/permit">
            <DropdownMenuItem className="cursor-pointer">
              Account Utility Scripts
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/account-dashboard">
            <DropdownMenuItem className="cursor-pointer">
              Account Dashboard
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/checkpermissions">
            <DropdownMenuItem className="cursor-pointer">
              Permissions Checker
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/Documents">
            <DropdownMenuItem className="cursor-pointer">
              Documents
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/reports/acronyms">
            <DropdownMenuItem className="cursor-pointer">
              MSR/QSR Acronyms
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/json-dashboard">
            <DropdownMenuItem className="cursor-pointer">
              JSON Dashboard
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/roadmap">
            <DropdownMenuItem className="cursor-pointer">
              Roadmap
            </DropdownMenuItem>
          </Link>
          <EmpowerLink
            isAdmin={true}
            dataSource="json-staging"
            label="Empower JSON-Staging"
            url="-json-staging"
            includeSessionData={true}
          />
          <Link href="">
            <DropdownMenuItem className="cursor-not-allowed text-gray-500">
              Empower JSON
            </DropdownMenuItem>
          </Link>
        </DropdownMenuContent>
      </DropdownMenu>
    </NavigationMenuItem>
  )
}
