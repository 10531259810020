"use client"
import { getAuthenticatedUser } from "@/app/_utils/permissions/actions/getAuthenticatedUser"
import { DropdownMenuItem } from "@/app/components/ui/dropdown-menu"
import {
  NavigationMenuLink,
  navigationMenuTriggerStyle,
} from "@/app/components/ui/navigation-menu"
import type { Session } from "next-auth"
import Link from "next/link"
import useSWR from "swr"

interface EmpowerUserAtts {
  UserName: string
  UserUpdate: number
  UserGroup?: string[]
  NarrRoleName?: string
  AiRoleName?: string
  AdminUser?: "T" | "F"
  PubLevel?: number
  Email?: string
  DisplayName?: string
  UserCode?: string
  Prefilter?: string
}

export function generateEmpowerLink(
  dataSource: string,
  data: Session,
  isAdmin: boolean,
  emailAuth = false,
  url = "",
  includeSessionData = false
): string {
  const userAtts = getEmpowerUserAtts(data, emailAuth, isAdmin)
  const userInfo = JSON.stringify(userAtts)
  return (
    `/empower${url}/login.html?sd=${dataSource}` +
    (includeSessionData ? `&sa=${btoa(userInfo)}` : "")
  )
}

function getEmpowerUserAtts(
  session: Session,
  emailAuth: boolean,
  isAdmin: boolean
): EmpowerUserAtts {
  let username = ""
  if (emailAuth) {
    username = session?.user?.email ?? ""
  } else {
    const suffix = session?.user?.email
      ? session?.user?.email.split("@")[0].toUpperCase()
      : ""
    username = `PARS\\${suffix}`
  }

  return {
    UserName: username,
    UserUpdate: 3,
    UserGroup: undefined,
    NarrRoleName: undefined,
    AiRoleName: undefined,
    AdminUser: isAdmin ? "T" : "F",
    Email: emailAuth ? username : undefined,
    DisplayName: session?.user?.name ?? undefined,
    UserCode: undefined,
    Prefilter: undefined,
  }
}

export function EmpowerNavMenuLink({
  isAdmin = false,
  dataSource = "empower_pgmigrated",
  label = "Empower",
  url = "",
}: Readonly<{
  isAdmin: boolean
  dataSource?: string
  label?: string
  url?: string
}>) {
  const { data } = useSWR("getAuthenticatedUser", getAuthenticatedUser)

  if (!data) {
    return (
      <NavigationMenuLink href="" className={navigationMenuTriggerStyle()}>
        {label}
      </NavigationMenuLink>
    )
  }
  if (data) {
    if (
      data?.user?.email?.endsWith("@doepars.com") ||
      data?.user?.email?.endsWith("@pars.doe.gov") ||
      data?.user?.email?.endsWith("@parspm.onmicrosoft.com")
    ) {
      console.error("User is has a PARS account name as an email address")
      return (
        <NavigationMenuLink href="" className={navigationMenuTriggerStyle()}>
          {label}
        </NavigationMenuLink>
      )
    } else {
      return (
        <NavigationMenuLink
          href={generateEmpowerLink(dataSource, data, isAdmin, false, url)}
          className={navigationMenuTriggerStyle()}
        >
          {label}
        </NavigationMenuLink>
      )
    }
  }
}

export function EmpowerLink({
  isAdmin = false,
  dataSource = "empower_pgmigrated",
  label = "Empower",
  url = "",
  includeSessionData = false,
}: Readonly<{
  isAdmin: boolean
  dataSource?: string
  label?: string
  url?: string
  includeSessionData?: boolean
}>) {
  const { data } = useSWR("getAuthenticatedUser", getAuthenticatedUser)

  if (!data) {
    return (
      <Link href="">
        <DropdownMenuItem>{label}</DropdownMenuItem>
      </Link>
    )
  }
  if (data) {
    if (
      data?.user?.email?.endsWith("@doepars.com") ||
      data?.user?.email?.endsWith("@pars.doe.gov") ||
      data?.user?.email?.endsWith("@parspm.onmicrosoft.com")
    ) {
      return (
        <Link href="">
          <DropdownMenuItem>{label}</DropdownMenuItem>
        </Link>
      )
    } else {
      return (
        <Link
          href={generateEmpowerLink(
            dataSource,
            data,
            isAdmin,
            true,
            url,
            includeSessionData
          )}
        >
          <DropdownMenuItem className="cursor-pointer">
            {label}
          </DropdownMenuItem>
        </Link>
      )
    }
  }
}
